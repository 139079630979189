import React from 'react';

class Cities extends React.Component {
  static barcelona = 'Barcelona';
  static berlin = 'Berlin';
  static london = 'London';
  static poznan = 'Poznan';
  static warzawa = 'Warzawa';
}

export default Cities;