import React from 'react';
import Video from './Video.js';
import './captionedVideo.css';

class CaptionedVideo extends React.Component {  
  constructor(props) {
    super(props);
    this.onProgress = this.onProgress.bind(this);   
  }

  onProgress(position) {
    this.props.onProgress(position);
  }

  render() {
    return(
      <div className='captionedVideo'>
        <div className='videoHolder'>
          <Video style={ this.props.style } 
                 content={ this.props.content } 
                 position={ this.props.position } 
                 onProgress={ this.onProgress } />
        </div>
        <div className='caption'>{ this.props.caption }</div>
      </div>      
    );
  }
}

export default CaptionedVideo;