import Cities from '../constants/Cities';
import Concepts from '../constants/Concepts';
import Colors from './Colors';
import Icons from './Icons';

export function colorFromConcept(concept, visited) {
  switch(concept) {
    case Concepts.commons : return visited ? Colors.commonsDarker : Colors.commons;
    case Concepts.work : return visited ? Colors.workDarker : Colors.work;
    case Concepts.data : return visited ? Colors.dataDarker : Colors.data;
    case Concepts.economy : return visited ? Colors.economyDarker : Colors.economy;
    case Concepts.space : return visited ? Colors.spaceDarker : Colors.space;
    case Concepts.governance : return visited ? Colors.governanceDarker : Colors.governance;
    default : return Colors.transparent;
  }
}

export function iconFromElement(element) {
  if (!element || !element.city || !element.concept) {
    return null;
  }
  let icons = [];
  let cityIcon = iconFromCityOrConcept(element.city);
  let conceptIcon = iconFromCityOrConcept(element.concept);
  icons.push(cityIcon, conceptIcon);
  return icons;
}

function iconFromCityOrConcept(cityOrConcept) {
  switch(cityOrConcept) {
    case Cities.barcelona : return Icons.barcelona;
    case Cities.berlin : return Icons.berlin;
    case Cities.london : return Icons.london;
    case Cities.poznan : return Icons.poznan;
    case Cities.warzawa : return Icons.warzawa;

    case Concepts.commons : return Icons.commons;
    case Concepts.work : return Icons.work;
    case Concepts.data : return Icons.data;
    case Concepts.economy : return Icons.economy;
    case Concepts.space : return Icons.space;
    case Concepts.governance : return Icons.governance;
    default : return null;
  }
}