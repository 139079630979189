//import { ForceGraph3D } from 'react-force-graph'
import { colorFromConcept } from '../../constants/converter';
import Icons from '../../constants/Icons';
import LabeledIcon from '../../base/icons/LabeledIcon';
import Colors from '../../constants/Colors';
import './nodes.css';

/**if (node.group !== undefined) {
  const value = 5; //Math.random() * 5 + 1;
  links.push(new Link(node.key, node.group, value));
}*/
//<ForceGraph3D graphData={ props.data }/>,


export default function Nodes(props) {
  const isSelected = (city, concept) => props.selection[0].get(city) && props.selection[1].get(concept);
    
  const onOpenModal = (element) => {
    if (element.group !== undefined) {
      props.onOpenModal(element);
    }
  }

  return (
    <>
    
    <div className='nodes'>
      { props.data.map(element => {
        const isTag = element.group === undefined;
        const key = element.key;
        const color = isSelected(element.city, element.concept) || props.isMapVisible ? colorFromConcept(element.concept, element.visited) : Colors.transparent
        return (
          <div className='element' style={ { 'left': element.x, 'top': element.y } } key={ key }>
            <LabeledIcon className='node'
                         title={ element.url }
                         iconClassName='nodeIcon'
                         label={ props.isMapVisible ? '' : (isTag) ? key : '' }
                         key={ Number.isNaN(key) ? '' : key }
                         icon={ Icons.circleIcon } 
                         color= { color } 
                         onClick={ () => onOpenModal(element) } >              
            </LabeledIcon>
          </div>
        )
      })}
    </div></>
  )
};