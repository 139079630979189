import React from 'react';
import Colors from '../../constants/Colors'
import Icons from '../../constants/Icons'
import Cities from '../../constants/Cities'
import LabeledIcon from '../../base/icons/LabeledIcon'
import './cities.css'

export default function CitiesFooter(props) {
  const isSelected = label => props.selection[0].get(label);
  
  return (
    <div className='cities'>        
      <LabeledIcon className={ isSelected(Cities.barcelona) ? 'selected' : 'city' }
                   iconClassName='footerIcon'                     
                   label={ Cities.barcelona } 
                   icon={ Icons.barcelona }
                   color={ Colors.white }
                   onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
      <LabeledIcon className={ isSelected(Cities.berlin) ? 'selected' : 'city' }
                    iconClassName='footerIcon'
                    label={ Cities.berlin } 
                    icon={ Icons.berlin }
                    color={ Colors.white }
                    onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
      <LabeledIcon className={ isSelected(Cities.london) ? 'selected' : 'city' }
                    iconClassName='footerIcon'
                    label={ Cities.london } 
                    icon={ Icons.london }
                    color={ Colors.white }
                    onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
      <LabeledIcon className={ isSelected(Cities.poznan) ? 'selected' : 'city' }
                    iconClassName='footerIcon'
                    label={ Cities.poznan } 
                    icon={ Icons.poznan }
                    color={ Colors.white }
                    onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
      <LabeledIcon className={ isSelected(Cities.warzawa) ? 'selected' : 'city' }
                    iconClassName='footerIcon'
                    label={ Cities.warzawa } 
                    icon={ Icons.warzawa }
                    color={ Colors.white }
                    onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
    </div>
  );
}  