import React, { createRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import './audio.css';

class Audio extends React.Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.onProgress = this.onProgress.bind(this);
  }

  componentDidMount() {
    const position = this.props.position;
    this.ref.current.seekTo(position);   
  }

  onProgress(progress) {
    const position = progress.playedSeconds;
    this.props.onProgress(position);
  }

  render() {
    const config = {
      soundcloud: {
        options: {
          show_user: false,
        }
      },
      file: {
        attributes: {
          crossOrigin: 'true'
        }
      }
    }
    return (    
      <ReactPlayer width={ this.props.style.maxWidth }
                   height={ this.props.style.maxHeight }
                   ref={ this.ref }
                   url={ 'http://soundcloud.com/' + this.props.content }
                   controls={ true } 
                   playing={ true }
                   onProgress={ progress => this.onProgress(progress) } 
                   config={ config } />
    );
  }
}

export default Audio;