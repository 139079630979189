import { createRef, useEffect } from 'react'
import Image from '../image/Image'
import './map.css'

export default function Map(props) {
  const mapRef = createRef();

  useEffect(() => {
    mapRef.current.scrollTo((mapRef.current.scrollWidth - window.innerWidth) / 2, 0);
  }, [mapRef]);

  return (
    <div className='map' key='map' ref={ mapRef }>
      <Image selection={ props.selection }
             isMapVisible={ props.isMapVisible }
             data={ props.data }
             onOpenModal={ element => props.onOpenModal(element) } />
    </div>
  ); 
}