import { useState, useEffect } from 'react';
import LockerItems from './LockerItems';
import UIStrings from '../constants/UIStrings';
import Icons from '../constants/Icons';
import './locker.css';

export default function Locker(props) {
  
  const [headerHeight, setHeaderHeight] = useState(0);
  const [header, setHeader] = useState(null);
  
  useEffect(header => {
    if (header) {
      setHeaderHeight(header.clientHeight + 'px');
    }
  }, [header]);

  const lockerClassName = props.isLockerShown ? props.isLockerExpanded ? 'locker lockerExpanded' : 'locker lockerShown' : 'locker lockerClosed';
  
  return (
    <div className={ lockerClassName } >
      <div className='lockerHeader' ref={el => (setHeader(el)) }>
        <div className='lockerIconNText'>
          <div className='lockerIcon'>{ Icons.lockerIcon }</div>
          <div className='lockerText'>{ UIStrings.boxLocker }</div>
        </div>
        <div className='lockerIcon'>
          <div onClick={ props.onCloseLocker }>{ Icons.closeIcon }</div>
          <div onClick={ __ => props.onExpandLocker(!props.isLockerExpanded) }>{ Icons.expandIcon }</div>
        </div>
      </div>
      <LockerItems isLockerShown={ props.isLockerShown }
                    isLockerExpanded={ props.isLockerExpanded }
                    onOpenLockerItem={ props.onOpenLockerItem }
                    onRemoveLockerItem={ props.onRemoveLockerItem }
                    headerHeight={ headerHeight } />
    </div>    
  );
}