import UIStrings from '../../constants/UIStrings'
import './title.css'

export default function Title(props) {
  const style = { filter: (props.isLockerShown || props.isModalVisible) ? 'blur(1px)' : 'none' };    

  return (
    <div className={ 'title' } style={ style }>
      <div>{ UIStrings.title0 }</div>
      <div>{ UIStrings.title1 }</div>
      <div>{ UIStrings.title2 }</div>
    </div>
  );
}