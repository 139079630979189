import React from 'react';
import Colors from '../../constants/Colors'
import Icons from '../../constants/Icons'
import Concepts from '../../constants/Concepts'
import LabeledIcon from '../../base/icons/LabeledIcon'
import './concepts.css'

export default function ConceptsFooter(props) {
  const isSelected = label => props.selection[1].get(label);

  return (
    <div className='concepts'>
      <LabeledIcon className={ isSelected(Concepts.commons) ? 'selected' : 'concept' }
                   iconClassName='footerIcon'
                   label={ Concepts.commons }
                   icon={ Icons.commons } 
                   color={ Colors.commons } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ isSelected(Concepts.work) ? 'selected' : 'concept' }
                   iconClassName='footerIcon'
                   label={ Concepts.work }
                   icon={ Icons.work } 
                   color={ Colors.work } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ isSelected(Concepts.data) ? 'selected' : 'concept' }
                   iconClassName='footerIcon'
                   label={ Concepts.data }
                   icon={ Icons.data } 
                   color={ Colors.data } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ isSelected(Concepts.economy) ? 'selected' : 'concept' }
                   iconClassName='footerIcon'
                   label={ Concepts.economy }
                   icon={ Icons.economy } 
                   color={ Colors.economy } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ isSelected(Concepts.space) ? 'selected' : 'concept' }
                   iconClassName='footerIcon'
                   label={ Concepts.space }
                   icon={ Icons.space } 
                   color={ Colors.space } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ isSelected(Concepts.governance) ? 'selected' : 'concept' }
                   iconClassName='footerIcon'
                   label={ Concepts.governance }
                   icon={ Icons.governance } 
                   color={ Colors.governance } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
    </div>
  );
}