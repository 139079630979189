import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './slideShow.css'

class SlideShow extends React.Component {  
  render() {
    return(
      <div className='captionedSlideShow'>
        <ImageGallery className='slideShow'
                      items={ this.props.content } 
                      infinite={ true }
                      lazyLoad={ true }
                      showIndex={ true }
                      showNav={ true }
                      showThumbnails={ false }
                      showFullscreenButton={ true }
                      useBrowserFullscreen={ true }
                      showPlayButton={ true }
                      showBullets={ true }
                      startIndex={ this.props.position }
                      onSlide={ this.props.onSlide }/>
        <div className='caption'>{ this.props.caption }</div>
      </div>
    )
  };
}

export default SlideShow;