import { useRef } from 'react';
import { DB } from '../base/db/DB';
import Icons from '../constants/Icons';
import { useLiveQuery } from 'dexie-react-hooks';
import './lockerItems.css';

export default function LockerItems(props) {
  const loaded = useRef(false);

  const lockerItems = useLiveQuery(async () => {
    const aux = await DB.lockerItems.toArray();
    loaded.current = true;
    return aux;
  });

  const lockerGridClass = props.isLockerShown ? props.isLockerExpanded ? 'lockerGridExpanded' : 'lockerGridShown' : null;
  const lockerItemClass = props.isLockerShown ? props.isLockerExpanded ? 'lockerItemExpanded' : 'lockerItemShown' : null;
  
  return (
    <div className={ lockerGridClass } style={ { height: `calc(100dvh - 2dvw - ${ props.headerHeight })`, minHeight: `calc(100dvh - 2dvw - ${ props.headerHeight })` } }>
      { lockerItems?.map(lockerItem => (
          <div className='lockerItemTile' key={ lockerItem.key } title={ lockerItem.url }>
            <img className={ lockerItemClass } src={ lockerItem.image } onClick={ e => props.onOpenLockerItem(lockerItem.key) } alt='thumbnail'/>      
            <div className='lockerItemCloseIcon' onClick={ e => props.onRemoveLockerItem(lockerItem.key) }>{ Icons.closeIcon }</div>
          </div>
        ))
      }
    </div>  
  );
}