import React from 'react';

class Colors extends React.Component {
    static commons = 'hsl(171, 100%, 50%)';
    static commonsDarker = 'hsl(171, 100%, 20%)';
    static work = 'hsl(5, 100%, 50%)';
    static workDarker = 'hsl(5, 100%, 20%)';
    static data = 'hsl(122, 100%, 50%)';
    static dataDarker = 'hsl(122, 100%, 20%)';
    static economy = 'hsl(56, 100%, 50%)';
    static economyDarker = 'hsl(56, 100%, 20%)';
    static space = 'hsl(302, 96%, 59%)';
    static spaceDarker = 'hsl(302, 96%, 29%)';
    static governance = 'hsl(270, 100%, 60%)';
    static governanceDarker = 'hsl(270, 100%, 30%)';

    static white = '#ffffff';
    static blue = '#001dff';
    static grey = '#00000072';
    static black = '#000000';

    static transparent = '#00000000';
}

export default Colors; 