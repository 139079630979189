class Node {
  constructor(key, group, x, y, concept, city, mediaType, position, content, caption, credits, url, visited) {
    this.key = key;
    this.group = group;
    this.x = x;
    this.y = y;
    this.concept = concept;
    this.city = city;
    this.mediaType = mediaType;
    this.position = position;    
    this.content = content;
    this.caption = caption;
    this.credits = credits;
    this.url = url;
    this.visited = visited;
  }
}

export default Node;