import Node from './Node';
import data from './Data';

let nodes = [];

data.forEach(node => {
  nodes.push(new Node(node.key, 
                      node.group, 
                      node.x, 
                      node.y,
                      node.concept,
                      node.city,
                      node.mediaType,
                      0,
                      node.content,
                      node.caption,
                      node.credits,
                      node.url,
                      false));  
});

export default nodes;