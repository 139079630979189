import React from 'react';

class UIStrings extends React.Component {
    static title0 = 'STADT';
    static title1 = 'ALS';
    static title2 = 'BYTE';

    static dots = 'More';
    static locker = 'Locker';
    static map = 'Map';
    static text = 'Text';
    static mapOrText = 'Map\u00a0\u00a0Text';
    static mute = 'Mute';
    static unmute = 'Unmute'
    static info = 'Intro';
    static boxLocker = 'My\u00a0Box\nLocker';

    static snippetUpdated = 'Snippet in locker updated!';
    static snippetAdded = 'Snippet added to locker!';
    static snippetRemoved = 'Snippet removed from locker!';

    static about = 'About'
    static howTo = 'How to'
    static credits = 'Credits';

    static empty = '';
}
export default UIStrings;