import map from '../../img/map.svg';
import Nodes from '../nodes/Nodes';
import './image.css';

export default function Image(props) {
  const imageStyle = { backgroundImage: `url(${ map })` };
  const imageShowHideStyle = props.isMapVisible ? { backgroundSize: 'cover', backgroundColor: 'white' } : 
                                                  { backgroundSize: '0', backgroundColor: 'black'};
  return (
    <div className='image' style={ Object.assign(imageStyle, imageShowHideStyle) }>
      <Nodes isMapVisible={ props.isMapVisible } 
             selection={ props.selection } 
             data={ props.data }
             onOpenModal={ props.onOpenModal }/>
    </div>
  );
}