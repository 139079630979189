import Cities from './cities/Cities';
import Concepts from './concepts/Concepts';
import './footer.css';

export default function Footer(props) {
  return (
    <div className='footer'>
      <div className='iconHolder'>
        <Concepts selection={ props.selection }                       
                  onConcept={ (concept, isSelected) => props.onConcept(concept, isSelected) } />
        <Cities selection={ props.selection }
                onCity={ (city, isSelected) => props.onCity(city, isSelected) } />
      </div>
    </div>
  );
}