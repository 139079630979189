import React from 'react';
import './image.css';

export default function Image(props) {  
  return(
    <div className='captionedImage'>
      <div className='imageHolder'>
        <img className='content' src={ props.content } alt=""/>
      </div>        
      <div className='caption'>{ props.caption }</div>
    </div>
  )
}