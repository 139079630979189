import React from 'react';
import './text.css';

class Text extends React.Component {
  render() {
    return(
      <div className='quote'>{ this.props.content }</div>
    )
  }
}

export default Text;