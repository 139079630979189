import { useParams } from 'react-router-dom';
import AbstractMedia from '../../data/mediatypes/AbstractMedia';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './dialog.css';

export default function Dialog(props) {
  let { dialogUrl } = useParams();
  const element = props.data.find(elem => elem.url === dialogUrl);
  
  const maxWidth = Math.min(1000, window.innerWidth * .9);
  const maxHeight = maxWidth === 1000 ? window.innerHeight / window.innerWidth * 1000 : window.innerHeight * .9;
  const style = {
    maxWidth: maxWidth,
    maxHeight: maxHeight
  }

  return (
    <div className='dialog'>
      <Modal open={ true }
             center
             focusTrapped={ true }
             showCloseIcon={ false }
             onClose={ () => props.onCloseModal(false) }
             closeOnOverlayClick={ true }
             closeOnEsc={ true }>            
        <AbstractMedia element={ element }
                       position={ props.position }
                       style={ style }
                       onAddLockerItem={ props.onAddLockerItem }
                       onCloseModal={ () => props.onCloseModal(false) }/>
      </Modal> 
    </div>
  );  
}