import React from 'react';

class Credits extends React.Component {
    static gunes012021 = 'Güneş Tavmen, researcher';
    static kuba122020 = 'Kuba Depczyński, curator';
    static tytus012021 = 'Tytus Szabelski, artist';
    static wouter112020 = 'Wouter Tebbens, media activist';
    static scott012021 = 'Scott Rodgers, researcher';
    static anne = 'Anne Huffschmid, researcher';
    static diana = 'Diana Lucas-Drogan, artist and planer';
    static tbd = "Credits to be determined";
}

export default Credits;
