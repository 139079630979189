import React, { createRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import './video.css';

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.onProgress = this.onProgress.bind(this);
  }
  
  componentDidMount() {
    const position = this.props.position;
    this.ref.current.seekTo(position);
  }

  onProgress(progress) {
    const position = progress.playedSeconds;
    this.props.onProgress(position);
  }

  render() {
    return(    
      <ReactPlayer ref={ this.ref }
                   width={ this.props.style.maxWidth }
                   height={ this.props.style.maxWidth / 16 * 8.8 }
                   url={ 'http://vimeo.com/' + this.props.content }                   
                   controls={ true }
                   playing={ true }                   
                   onProgress={ progress => this.onProgress(progress) } 
                   config={ 
                     {                         
                       vimeo: {
                         playerOptions: {
                           colors: ['000000','ffffff','ffffff','000000']
                         }
                       }
                     }
                   } />
    );
  }
}

export default Video;