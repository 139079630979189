import React from 'react';

class MediaTypes extends React.Component {
  static audio = 'Audio';
  static pdf = 'PDF';
  static image = 'Image';
  static slideshow = 'Slideshow';
  static text = 'Text';
  static video = 'Video';
  static captionedVideo = 'Captioned Video';
}

export default MediaTypes;    