import React from 'react';

class Concepts extends React.Component {
    static commons = 'Commons';
    static work = 'Work';
    static data = 'Data';
    static economy = 'Economy';
    static space = 'Space';    
    static governance = 'Governance';  
}

export default Concepts;